import React from 'react';

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

const JobPage = () => {
  return (
    <>
      <SEO title="CEO Assistant" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>CEO Assistant</h1>

          <h3 className={styles.textSubHeader}>
            Rosteme, a tak hledáme kolegu/yni na pozici Personal Assistant pro
            CEO.
          </h3>
          <p className={styles.text}>
            Chceš být součástí mladého a rozjetého týmu s nadšením pro věc? Baví
            tě zdravý životní styl? Jedeš na výkon, jsi proaktivní a sebevědomě
            si prosadíš vlastní nápady? Možná jsi to ty!
          </p>
          <p className={styles.text}>
            NutritionPro – jsme úspěšný a rostoucí startup z oblasti FoodTech.
            Vyvinuli jsme algoritmus, který na základě tvých tělesných údajů
            vytvoří jídelníček na míru. A to je teprve začátek - přesně
            spočítaná jídla pro tebe taky uvaříme a dovezeme!
          </p>

          <h3 className={styles.textSubHeader}>
            Co obnáší role pravé ruky našeho CEO?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Příprava interních reportů a statistik pro CEO
            </li>
            <li className={styles.newList}>
              Administrativa a příprava dokumentace k podpisu
            </li>
            <li className={styles.newList}>
              Přehled, koordinace a příprava podkladů pro schůzky a jednání
            </li>
            <li className={styles.newList}>Personální úkoly od CEO</li>
            <li className={styles.newList}>
              Pravidelná komunikace s investory společnosti
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co od tebe očekáváme?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>Zkušenosti z obdobné pozice</li>
            <li className={styles.newList}>Tech Savvy osobnost</li>
            <li className={styles.newList}>
              Dokonalou znalost MS Excelu nebo Google Sheets
            </li>
            <li className={styles.newList}>
              Schopnost řešit problémy a dotahovat věci do konce
            </li>
            <li className={styles.newList}>
              Organizační a komunikační dovednosti na vysoké úrovni
            </li>
            <li className={styles.newList}>
              Proaktivní přístup, zodpovědnost, spolehlivost, loajalita vůči
              zaměstnavateli
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Jazykové znalosti:</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Dokonalou češtinu slovem i písmem{" "}
            </li>
            <li className={styles.newList}>
              Znalost ruštiny je výhoda, ale ne nutnost
            </li>
            <li className={styles.newList}>
              Komunikativní angličtina je bonus navíc
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>Super práci na Full-time</li>
            <li className={styles.newList}>
              Mentoring rovnou od zakladatele společnosti v rámci práce
            </li>
            <li className={styles.newList}>Měsíční bonusy</li>
            <li className={styles.newList}>Sick-days</li>
            <li className={styles.newList}>Příspěvky na vzdělávací kurzy</li>
            <li className={styles.newList}>
              Multisport kartu plně hrazenou firmou
            </li>
            <li className={styles.newList}>Firemní obědy zdarma</li>
            <li className={styles.newList}>Fitko přímo v kancelářích</li>
            <li className={styles.newList}>
              Vodu, kávu, čaj, džusy, čerstvé ovoce na pracovišti dle libosti
            </li>
          </ul>

          <p className={styles.text}>
            Sídlíme v moderních kancelářích v srdci Karlína, takže jako bonus tě
            čeká i skvělé prostředí pro networking, neformální atmosféra bez
            dress code a velká terasa s výhledem na Prahu!
          </p>

          <h3 className={styles.textSubHeader}>
            Těšíme se na tvoje CV a rádi tě uvítáme v našem týmu NutritionPro!
          </h3>
          <h3 className={styles.textSubHeader}>Kontakty:</h3>
          <a className={styles.jobsEmail} href="mailto:ana@nutritionpro.cz">
           jobs@nutritionpro.cz
          </a>
          {/* <a className={styles.jobsEmail} href="tel:+420608068463">
            +420 608 068 463
          </a> */}
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_ceoAssistant" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
